import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from 'components/global/layout'
import LegalWrapper from 'components/legal/legalWrapper'
import PageHeader from 'components/shared/pageHeader'
import BackToHome from 'components/global/backToHome'

const PrivacyPolicy = ({ data }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  const meta = {
    title: 'Privacy Policy | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <Link to="/">
        <span className="sr-only">CLICK HERE</span>
        <PageHeader mobileHeader={mob} desktopHeader={desk} />
      </Link>
      <LegalWrapper title="Privacy Policy">
        <BackToHome />
        <h2>Introduction</h2>
        <p>
          This website, controlled by Mousetrap Productions Limited, provides
          marketing information about The Mousetrap. Mousetrap Productions
          Limited is the Data Controller, for the purposes of Data Protection
          Law, regarding any personal information that is processed as a result
          of your visit to this website.
        </p>
        <p>
          We are committed to providing you with relevant information about, and
          control over, how we process your personal information.
        </p>
        <p>
          This policy explains what personal information we may record, why we
          do so, how you may control the use of your information and how we
          protect your information. Details of your legal rights and how to
          exercise them are also set out below.
        </p>
        <p>
          This policy was last updated on 27 January 2022 and any future changes
          to our policy will be reflected here.
        </p>
        <h2>Contacting us</h2>
        <p>
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed by email to{' '}
          <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a> or by
          post to Mousetrap Productions Limited c/o First Floor, 11 Garrick
          Street, London WC2E 9AR.
        </p>
        <h2>What does this policy cover?</h2>
        <p>
          This Privacy Policy applies only to your use of this website. The
          website may contain links to other websites, please note that we have
          no control over how your data is collected, stored, or used by other
          websites; and we advise you to check the privacy policies of any such
          websites before providing any data to them.
        </p>
        <h2>What is personal data?</h2>
        <p>
          Personal data is defined by the General Data Protection Regulation (EU
          Regulation 2016/679) (the &ldquo;GDPR&rdquo;) and the Data Protection
          Act 2018 (collectively, &ldquo;the Data Protection Legislation&rdquo;)
          as &lsquo;any information relating to an identifiable person who can
          be directly or indirectly identified in particular by reference to an
          identifier&rsquo;.
        </p>
        <p>
          Personal data is, in simpler terms, any information about you that
          enables you to be identified. Personal data covers obvious information
          such as your name and contact details, but it also covers less obvious
          information such as identification numbers, electronic location data,
          and other online identifiers.
        </p>
        <h2>What personal information do we gather?</h2>
        <p>
          We or service providers working on our behalf, may collect the
          following types of information:
        </p>
        <ul>
          <li>
            Device information including IP address, browser type and version,
            operating system and cookie IDs. The data is used to understand how
            people interact with the website and for other purposes as described
            below.
          </li>
          <li>
            Usage information such as how frequently and how long you use our
            site and the content you view, which may be used to tailor our
            website, perform analytics, and provide advertising as further
            described below.
          </li>
          <li>
            Contact information you provide to us such as email addresses only
            if you sign up for our newsletter or participate in our promotions
            as further described below.
          </li>
        </ul>
        <p>
          Our legal basis for collecting personal information is your consent.
          We gain consent either when you approve our use of cookies and other
          tracking technologies when you use the website or when you
          specifically provide&nbsp;contact details to enter a promotion on the
          website and agree to further contact.
        </p>
        <h2>Why do we process your personal information?</h2>
        <p>
          We process your personal information gathered on this website in order
          to review and improve the site, marketing, and to improve it for
          future customers. The specific purposes for which we may process
          information, and the lawful basis on which we do so in each case, are
          set out below.
        </p>
        <p>
          We will keep your information only for as long as it is necessary to
          fulfil the purposes described below unless a longer retention is
          required by law, after which we will delete or anonymise it. The
          period we keep your information will vary depending on your
          interactions with us. For example, we may keep a record of
          correspondence with us, for example, if you have made a subject access
          request, for as long as is legally necessary. Similarly, when you
          unsubscribe from our email newsletters or promotional emails, we will
          keep a record of your email address to ensure we do not send you
          marketing emails in the future.
        </p>
        <p>
          We maintain procedural, technical, and physical safeguards to help
          protect against loss, misuse or unauthorised access, disclosure,
          alteration, or destruction of the information you provide. These
          safeguards vary depending upon the sensitivity of the information we
          collect and store. Please be aware that no security solutions are
          infallible.
        </p>
        <h2>Tailoring this website to your needs</h2>
        <p>
          We want to make our website easy to personalise for you. We use
          preference cookies to store your preferences, such as website language
          selection, so that you do not need to make the same selections every
          time you visit our website. These cookies, stored by your browser on
          your device, will retain your preference choices for up to 90 days, or
          until you delete them. We provide the ability to accept or reject
          cookies when you first visit the website and these preferences can
          also be changed at a later date, details of which are in the cookie
          policy.
        </p>
        <h2>
          Contacting you with marketing emails to keep you informed about our
          show
        </h2>
        <p>
          We want to help you to hear more about our show. We provide facilities
          on this site for you to sign up to receive email newsletters to learn
          more about the show. We will send such newsletters by email on the
          basis of your specific consent when you supply your email details. You
          can stop receiving these emails at any time by clicking on the
          unsubscribe link which we will include in each email. Your
          information, and details of when you consented to receive marketing
          emails, will be stored in our marketing system and will be retained
          until the production finishes. We will not share this data with third
          parties for their own use, though we may engage appropriate suppliers
          to send emails on our behalf.
        </p>
        <h2>Improving the effectiveness of our website</h2>
        <p>
          We want to make sure that our website is successful in enabling people
          to learn about the show and to book tickets, and generally meets the
          needs of visitors. To do this we need to analyse how people use this
          site. Information like where visitors spend time and on which pages
          helps us make improvements. For example, we may use an analytics
          service to understand how you and other visitors use our website, for
          example recording in what order pages are visited and how long is
          spent on each, so that we may improve the website in future. The
          lawful basis on which we process this information is your consent. You
          may remove your consent at any time by clicking the Privacy Settings
          link at the bottom of the website pages.
        </p>
        <h2>
          Targeting and measuring the effectiveness of our digital marketing
        </h2>
        <p>
          We know that many people visit a show site several times to learn
          about the show, and dates when tickets are available, before deciding
          to book tickets. We want to make sure that our digital advertisements
          on other websites and social media are seen by people likely to be
          interested in the show, including by visitors to this site who have
          not yet booked. We also want to understand the effectiveness of our
          advertising in encouraging visits to the website and ticket bookings
          so that we may improve it in future. For example, we may review the
          choice of advertising design and the websites and advertising networks
          used to reach possible customers. We may use available browsing
          history, including visits to this website, to identify people to whom
          we wish to show advertisements on websites operated by third parties
          and to record how many visitors are referred to our site from other
          sites. We may use third-party advertising tracking cookies to achieve
          this with your consent.
        </p>
        <p>
          We respect any decision not to participate in personalised
          advertising, in which case third party sites may show you
          non-personalised advertising and you may still see advertisements for
          our show. You may opt out at any time by clicking the Privacy Settings
          link at the bottom of the website pages. Our tracking cookie
          information is not shared with third parties for their own use, though
          we may use it to reconcile advertising costs with the publishers of
          third-party sites.
        </p>
        <h2>Data transfers</h2>
        <p>
          The personal information collected while you use this website may be
          transferred to countries outside the European Economic Area (EEA).
          This may happen, for example, when the computer servers used to host
          the website or our advertising and marketing systems are located in a
          country outside the EEA. If we transfer your personal information
          outside of the EEA in this way, we will take steps to ensure that your
          privacy rights continue to be appropriately protected.
        </p>
        <h2>Do you share my personal data?</h2>
        <p>
          We may share data with service providers and advertising networks to
          provide the functions as described above. We will not share any of
          your personal data with any third parties for their own use or for any
          purposes, subject to the following exceptions:
        </p>
        <p>
          In some limited circumstances, we may be legally required to share
          certain personal data, which might include yours, if we are involved
          in legal proceedings or complying with legal obligations, a court
          order, the instructions of a government authority, or to prevent fraud
          and protect the security of our site.
        </p>
        <h2>Your rights</h2>
        <p>
          We want to be transparent about how we process your data and to ensure
          that you can exercise your legal rights:
        </p>
        <ul>
          <li>
            To be informed &ndash; via this Privacy Policy which sets out how we
            collect and process data
          </li>
          <li>
            To access your data &ndash; please contact{' '}
            <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a> if
            you wish to confirm what data we hold about you
          </li>
          <li>
            To have your data corrected &ndash; please contact{' '}
            <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a> if
            you feel that data held is incorrect and want this rectified
          </li>
          <li>
            To have processing of your data ceased &ndash; please contact{' '}
            <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a>if you
            wish us to cease processing your data without erasing it
          </li>
          <li>
            To have your data erased &ndash; please contact{' '}
            <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a>to
            request deletion of data that we hold about you
          </li>
          <li>
            Data portability &ndash; we process data only to personalise our
            services to you, so do not consider it appropriate to pass your data
            to other organisations. Some banks and utilities offer facilities to
            switch accounts, but this is not applicable to our business
          </li>
          <li>
            To object to processing of your data on the basis of legitimate
            interest or for statistical purposes &ndash; please contact{' '}
            <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a>,
            and/or use the Privacy Settings link at the bottom of website pages.
          </li>
        </ul>
        <h2>Complaints</h2>
        <p>
          To exercise all relevant rights, queries or complaints please in the
          first instance contact{' '}
          <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a>
        </p>
        <p>
          You also have the right to contact the Information Commissioners
          Office on 0303 123 1113 or via email
          https://ico.org.uk/global/contact-us/email/ or at the Information
          Commissioner&rsquo;s Office, Wycliffe House, Water Lane, Wilmslow,
          Cheshire. SK9 5AF. Before doing so, however, we would encourage you to
          first contact us directly to allow us an opportunity to resolve any
          concerns you may have.
        </p>
      </LegalWrapper>
    </Layout>
  )
}

export default PrivacyPolicy

export const PrivacyPolicyQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "cast-creative-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "cast-creative-header" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
